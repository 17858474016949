import React from 'react';
import { Link } from 'gatsby';

// @ts-ignore
import logo from './24cash_logo.jpg';
import { LanguageSelectionBox } from './LanguageSelectionBox';
import styled from 'styled-components';

const LanguageSelectionBoxWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 150px;
  height: 30px;
  align-items: flex-end;
  @media (max-width: 800px) {
    margin-top: 30px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
`;

export const alertall = xby => {
  console.log('Hallo,', xby);
};

export const Header = ({ siteTitle }) => (
  <div
    style={{
      background: 'none',
      marginBottom: '0.5rem',
    }}
  >
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        padding: '1.45rem 1.0875rem',
      }}
    >
      {/* <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: 'white',
            textDecoration: 'none',
          }}
        >
          {siteTitle}
        </Link>
      </h1> */}
      <TitleWrapper>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <div style={{ width: '100px' }}>
            <img style={{ maxWidth: '100%' }} src={logo} />
          </div>
          <div
            style={{ marginLeft: '50px', fontSize: '40px', lineHeight: '43px' }}
          >
            <span>{siteTitle}</span>
          </div>
        </div>

        {/* <LanguageSelectionBoxWrapper>
          <LanguageSelectionBox onLanguageChange={alertall} />
        </LanguageSelectionBoxWrapper> */}
      </TitleWrapper>
    </div>
  </div>
);
